import { initOptions } from '../options';
import { noticeError, recordMetrics } from './nr-agent-server';

export const contextKey = 'newRelic';

const defaultOptions = {
	isNoticeable: _err => true
};

export const recordMetric = (context, key, value) => {
	if (!context[contextKey]) {
		// Only attempt appending a metric if the New Relic wrapper is in use.
		return;
	}

	const { metrics } = context[contextKey];
	if (!metrics) {
		throw new Error(
			'The New Relic wrapper must be ordered before any wrappers that append metrics!'
		);
	}

	metrics[key] = value;
};

export const getWrapper = (nextAction, context) => {
	initOptions(context, contextKey, defaultOptions);
	return async (fnArgs, executionContext) => {
		const metrics = {};
		executionContext[contextKey].metrics = metrics;

		try {
			return await nextAction(fnArgs, executionContext);
		} catch (e) {
			if (executionContext[contextKey].options.isNoticeable(e)) {
				noticeError(e);
			}
			metrics.error = true;
			if (e.name) metrics['error.name'] = e.name;
			if (e.message) metrics['error.message'] = e.message;
			throw e;
		} finally {
			const prefix =
				executionContext[contextKey].options.prefix || executionContext.name;
			recordMetrics(prefix, metrics);
		}
	};
};

getWrapper.contextKey = contextKey;

let newrelic = window && window.newrelic;

if (!newrelic) {
	// eslint-disable-next-line no-console
	console.warn(
		'Unable to locate the NewRelic browser agent - performance metrics will not be recorded for this application!'
	);
	newrelic = {
		noticeError: () => {},
		addPageAction: () => {}
	};
}

export const noticeError = (...args) => newrelic.noticeError(...args);
export const recordMetrics = (...args) => newrelic.addPageAction(...args);

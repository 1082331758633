import * as abort from './abort';
import * as circuitBreaker from './circuit-breaker';
import * as contextTerminus from './context-terminus';
import * as duration from './duration';
import * as fetch from './fetch';
import * as newRelic from './newrelic';
import * as retry from './retry';
import * as timeout from './timeout';

export const allWrappers = {
	abort,
	circuitBreaker,
	contextTerminus,
	duration,
	fetch,
	newRelic,
	retry,
	timeout
};

const defaultWrappers = [
	newRelic,
	duration,
	circuitBreaker,
	retry,
	timeout,
	abort,
	contextTerminus
];

/**
 * Get the default list of wrappers to use in the fault tolerant execution chain.
 *
 * @param {boolean} [terminateContext] - [Optional] By default, the wrapper execution chain will expand the
 *  arguments array before passing to the execution terminus (the function supplied when building the
 *  fault tolerant chain with `hysteriCall()`). Setting this argument to false will result in the execution
 *  terminus receiving two arguments instead:
 *      (1) The function arguments in its original array form from the invocation
 *      (2) The execution chain context.
 *
 * @returns {Function[]} The default list of wrappers.
 */
export const getDefaultWrappers = (terminateContext = true) => {
	return terminateContext
		? defaultWrappers
		: defaultWrappers.filter(wrapper => wrapper !== contextTerminus);
};

import { hysteriCall } from './hystericall';
import { allWrappers, getDefaultWrappers } from './wrappers';

/**
 * Create a call wrapper that will invoke `fetch` with the supplied (or default) fault tolerance wrappers
 * (e.g. circuit breaker, retry).
 *
 * @param {string} name - Name of the service or action you are wrapping. Should be unique in your application.
 * @param {Object} [options] - [Optional] Additional options to pass to wrappers, e.g. retry or circuit breaker options.
 * @param {Object[]} [wrappers] - [Optional] The intermediary wrappers to wrap your function with.
 *
 * @returns {HysteriFetchExecutor} An async executor function ({@link HysteriFetchExecutor}) that should be invoked
 *  instead of the `fetch` function whenever fault tolerant behavior is desired.
 */
export const hysteriFetch = (
	name,
	options = {},
	wrappers = [...getDefaultWrappers(false), allWrappers.fetch]
) => {
	if (wrappers[wrappers.length - 1] !== allWrappers.fetch) {
		throw new Error(
			'The wrappers array argument must end with the fetch wrapper!'
		);
	}

	const callOptions = {
		...options,
		[allWrappers.retry.contextKey]: {
			isRetryable: allWrappers.fetch.defaultIsRetryable,
			...(options ? options[allWrappers.retry.contextKey] : {})
		},
		[allWrappers.circuitBreaker.contextKey]: {
			errorFilter: allWrappers.fetch.defaultCircuitBreakerErrorFilter,
			...(options ? options[allWrappers.circuitBreaker.contextKey] : {})
		}
	};
	const call = hysteriCall(name, null, callOptions, wrappers);

	/**
	 * @function HysteriFetchExecutor
	 * @typedef {Function} HysteriFetchExecutor
	 *
	 * Within the fault tolerant context supplied by {@link hysteriFetch}, execute `fetch` and process the response
	 * body.
	 *
	 * @param {String} fetchUrl - The URL argument to `fetch`.
	 * @param {Object} [fetchOptions] - [Optional] The options argument to `fetch`. These options will override any options
	 *  specified when the executor was created with {@link hysteriFetch}.
	 * @param {Object} [optionOverrides] - [Optional] Execution time option overrides (for other wrappers).
	 *
	 * @returns {Promise<*>} A promise containing the fetch response body (if any).
	 */
	return async (fetchUrl, fetchOptions = {}, optionOverrides = {}) => {
		const mergedOptions = {
			...optionOverrides,
			[allWrappers.fetch.contextKey]: {
				...(fetchOptions || {}),
				...(optionOverrides
					? optionOverrides[allWrappers.fetch.contextKey]
					: {})
			}
		};
		return call([fetchUrl], mergedOptions);
	};
};

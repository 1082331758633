import { recordMetric } from './newrelic';

export const contextKey = 'duration';

export const getWrapper = nextAction => async (fnArgs, executionContext) => {
	const startTime = Date.now();
	try {
		return await nextAction(fnArgs, executionContext);
	} finally {
		const elapsed = Date.now() - startTime;
		executionContext[contextKey].elapsed = elapsed;
		recordMetric(executionContext, `${contextKey}.elapsed`, elapsed);
	}
};

getWrapper.contextKey = contextKey;

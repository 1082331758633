import { getDefaultWrappers } from './wrappers';

const createExecutionContext = (context, optionOverrides, wrappers) => {
	/* Shallow copy each wrapper context 'sandbox', except for the options
	 * field, which we also separately shallow copy. Copying in this way
	 * prevents the deep copy of possibly large and/or circular objects,
	 * but exposes us to potential future bugs associated with the shallow
	 * copying of deeply nested objects. */
	const executionContext = { ...context };
	wrappers.forEach(wrapper => {
		const { contextKey } = wrapper;
		executionContext[contextKey] = { ...context[contextKey] };
		executionContext[contextKey].options = {
			...executionContext[contextKey].options,
			...optionOverrides[contextKey]
		};
	});
	return executionContext;
};

/**
 * Create a call wrapper that will invoke your supplied function with the supplied (or default) fault tolerance
 * wrappers (e.g. circuit breaker, retry).
 *
 * @param {String} name - Name of the service or action you are wrapping. Should be unique in your application.
 * @param {Function} fn - The function to invoke.
 * @param {Object} [options] - [Optional] Additional options to pass to wrappers, e.g. retry or circuit breaker options.
 * @param {Object[]} [wrappers] - [Optional] The intermediary wrappers to wrap your function with.
 *
 * @returns {HysteriCallExecutor} An async executor function ({@link HysteriCallExecutor}) that should be invoked
 *  instead of the original function whenever fault tolerant behavior is desired.
 */
export const hysteriCall = (
	name,
	fn,
	options = {},
	wrappers = getDefaultWrappers()
) => {
	const context = createExecutionContext({ name }, options || {}, wrappers);

	// Build the execution chain upwards from the function.
	let executionChain = fn;
	wrappers
		.slice()
		.reverse()
		.forEach(wrapper => {
			executionChain = wrapper.getWrapper(executionChain, context);
		});

	/**
	 * @function HysteriCallExecutor
	 * @typedef {Function} HysteriCallExecutor
	 *
	 * Execute the function supplied to {@link hysteriCall} within a fault tolerant context.
	 *
	 * @param {*[]} [fnArgs] - [Optional] An array of arguments to the wrapped function.
	 * @param {Object} [optionOverrides] - [Optional] Execution time option overrides.
	 *
	 * @returns {Promise<*>} A promise containing the function invocation results (if any).
	 */
	return async (fnArgs = [], optionOverrides = {}) => {
		if (!Array.isArray(fnArgs)) {
			throw new Error('Function arguments must be provided as an array');
		}
		const executionContext = createExecutionContext(
			context,
			optionOverrides,
			wrappers
		);
		return executionChain(fnArgs, executionContext);
	};
};

import pTimeout from 'p-timeout';

import { initOptions } from './options';

export const contextKey = 'timeout';

const defaultOptions = {
	timeout: 600
};

export const getWrapper = (nextAction, context) => {
	initOptions(context, contextKey, defaultOptions);
	return async (fnArgs, executionContext) => {
		const { timeout } = executionContext[contextKey].options;
		return pTimeout(nextAction(fnArgs, executionContext), timeout);
	};
};

getWrapper.contextKey = contextKey;

export class FetchHttpError extends Error {
	constructor(fetchHttpResponse, description, upstreamError = null) {
		super();

		const { url, status, statusText } = fetchHttpResponse;

		this.status = status;
		this.statusText = statusText;
		this.url = url;
		this.message = `${status} ${statusText} (${url}) - ${description}`;
		this.name = `FetchHttpError${status}`;
		this.upstreamError = upstreamError;

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, FetchHttpError);
		} else {
			this.stack = new Error(this.message).stack;
		}
	}
}
